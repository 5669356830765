<template>
  <div class="clzj">
    <p style="text-align: left;">*评估结果：</p>
    <ul class="pgjg-list">
      <li v-for="item in pgjg" v-if="item">{{item}}</li>
    </ul>
    <p style="text-align: left;padding: 8px 0;">处理建议： <span class="jtxlfa" @click="$router.push({path: '/training/trainingPlan'})">具体训练方案</span></p>
    <div class="titles">
      <div class="titles-l">视功能评估</div>
      <div class="titles-r">
        <div class="titles-r-a">处理意见</div>
        <div class="titles-r-b">
          <div class="titles-r-b-a">矫正(附加)</div>
          <div class="titles-r-b-b">视觉训练</div>
        </div>
        <div class="titles-r-c">
          <div class="titles-r-c-a">
            <div class="titles-r-c-a-a">
              <p>正镜</p>
              <p>BO棱镜</p>
              <p>BI棱镜</p>
            </div>
            <div class="titles-r-c-a-b">
              <p></p>
              <p></p>
              <p></p>
            </div>
          </div>
          <div class="titles-r-c-b">
            <div class="titles-r-c-b-a">
              <div class="titles-r-c-b-a-a">调节训练</div>
              <div class="titles-r-c-b-a-b">
                <p>镜片阅读</p>
                <p>镜片排序</p>
                <p>调节幅度</p>
                <p>调节灵活度</p>
              </div>
            </div>
            <div class="titles-r-c-b-b">
              <p>远</p>
              <p>近</p>
              <p>远近交替</p>
              <p>近</p>
            </div>
          </div>
          <div class="titles-r-c-c">
            <div class="titles-r-c-c-a">
              <div class="titles-r-c-c-a-a">脱抑训练</div>
              <div class="titles-r-c-c-a-b">
                <p>远</p>
                <p>近</p>
                <p>增强</p>
              </div>
            </div>
            <div class="titles-r-c-c-b">
              <p>远</p>
              <p>近</p>
              <p>近</p>
            </div>
          </div>
          <div class="titles-r-c-d">
            <div class="titles-r-c-d-a">
              <div class="titles-r-c-d-a-a">融像训练</div>
              <div class="titles-r-c-d-a-b">
                <p>融像感知</p>
                <p>集合训练</p>
                <p>平滑融像</p>
                <p>跳跃融像</p>
                <p>自主融像</p>
              </div>
            </div>
            <div class="titles-r-c-d-b">
              <p>远</p>
              <p>近</p>
              <p>远近交替</p>
              <p>远</p>
              <p>近</p>
              <p>远</p>
              <p>近</p>
              <p>近</p>
            </div>
          </div>
          <div class="titles-r-c-e">
            <div class="titles-r-c-e-a">
              <div class="titles-r-c-e-a-a">动眼功能训练</div>
              <div class="titles-r-c-e-a-b">
                <p>追随扫视训练</p>
                <p>整合协调训练</p>
              </div>
            </div>
            <div class="titles-r-c-e-b">
              <p>近</p>
              <p>远</p>
              <p>近</p>
              <p>远</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="jl">
      <div class="jt-content">
        <div v-if="isshow[0].isshow" class="jt-content-nr">
          <div class="lef-nr">调节不足</div>
          <div class="content-right">
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
          </div>
        </div>
        <div v-if="isshow[1].isshow" class="jt-content-nr">
          <div class="lef-nr">调节过度</div>
          <div class="content-right">
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
          </div>
        </div>
        <div v-if="isshow[2].isshow" class="jt-content-nr">
          <div class="lef-nr">调节灵活度不良</div>
          <div class="content-right">
            <p></p>
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
          </div>
        </div>
        <div v-if="isshow[3].isshow" class="jt-content-nr">
          <div class="lef-nr">调节不能持久</div>
          <div class="content-right">
            <p></p>
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p></p>
          </div>
        </div>
        <div v-if="isshow[4].isshow" class="jt-content-nr">
          <div class="lef-nr">单纯性内隐斜</div>
          <div class="content-right">
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
          </div>
        </div>
        <div v-if="isshow[5].isshow" class="jt-content-nr">
          <div class="lef-nr">单纯性外隐斜</div>
          <div class="content-right">
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
          </div>
        </div>
        <div v-if="isshow[6].isshow" class="jt-content-nr">
          <div class="lef-nr">集合不足</div>
          <div class="content-right">
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
          </div>
        </div>
        <div v-if="isshow[7].isshow" class="jt-content-nr">
          <div class="lef-nr">集合过度</div>
          <div class="content-right">
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
          </div>
        </div>
        <div v-if="isshow[8].isshow" class="jt-content-nr">
          <div class="lef-nr">假性集合不足</div>
          <div class="content-right">
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p></p>
          </div>
        </div>
        <div v-if="isshow[9].isshow" class="jt-content-nr">
          <div class="lef-nr">散开不足</div>
          <div class="content-right">
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
          </div>
        </div>
        <div v-if="isshow[10].isshow" class="jt-content-nr">
          <div class="lef-nr">散开过度</div>
          <div class="content-right">
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
          </div>
        </div>
        <div v-if="isshow[11].isshow" class="jt-content-nr">
          <div class="lef-nr">融像性聚散减低</div>
          <div class="content-right">
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
            <p><i class="el-icon-check"></i></p>
          </div>
        </div>
        <div v-if="isshow[12].isshow" class="jt-content-nr">
          <div class="lef-nr">--</div>
          <div class="content-right">
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
          </div>
        </div>
        <div v-if="isshow[13].isshow" class="jt-content-nr">
          <div class="lef-nr">--</div>
          <div class="content-right">
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
            <p></p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "clzj",
  data() {
    return {
      pgjg: [],
      isshow: [
        {name: '调节不足', isshow: 0},
        {name: '调节过度', isshow: 0},
        {name: '调节灵活度不良', isshow: 0},
        {name: '调节不能持久', isshow: 0},
        {name: '单纯性内隐斜', isshow: 0},
        {name: '单纯性外隐斜', isshow: 0},
        {name: '集合不足', isshow: 0},
        {name: '集合过度', isshow: 0},
        {name: '假性集合不足', isshow: 0},
        {name: '散开不足', isshow: 0},
        {name: '散开过度', isshow: 0},
        {name: '融像性聚散减低', isshow: 0},
        {name: '垂直位双眼平衡失调', isshow: 0},
        {name: '非典型病例（待定）', isshow: 0}
      ]
    }
  },
  created() {
    let str = this.$store.state.eyeTest.ViewFnTest.S_OP_Json.S_OP_VF_Diagnosis
    this.pgjg = str?str.split(','):[]
    if (this.pgjg.length) {
      for (let i = 0; i < this.pgjg.length; i++) {
         for (let j = 0; j < this.isshow.length; j++) {
           if (this.pgjg[i] == this.isshow[j].name) {
             this.isshow[j].isshow = 1
           }
         }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .clzj {
    width: 880px;
    height: 64vh;
    overflow: hidden;
  }

  $bB: 1px solid #b1b0b0;

  .jl {
    height: 34vh;
    overflow: hidden;
    overflow-y: auto;
  }
  .jl::-webkit-scrollbar {
    display: none;
  }
  .jl::-webkit-scrollbar { width: 0 !important }
  .jl { -ms-overflow-style: none; }
  .jl { overflow: -moz-scrollbars-none; }
  .pgjg-list {
    display: flex;
    align-items: center;
    color: #ff0101;
    padding: 12px 0;
    border-top: 1px solid #b1b0b0;
    border-bottom: 1px solid #b1b0b0;
    margin: 5px 0;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    li {
      padding: 10px;
      margin-right: 20px;
      background: #e5e5e5;
      border-radius: 5px;
    }
  }
  .jtxlfa {
    padding: 5px;
    border: 1px solid #adadad;
    border-radius: 5px;
    cursor: pointer;
  }
  .titles {
    display: flex;
    justify-content: start;
    border-top: $bB;
    margin-top: 5px;
    .titles-l {
      width: 49px;
      //padding: 0 2px;
      height: 167px;
      display: flex;
      align-items: center;
      border-right: 1px solid #b1b0b0;
      border-bottom: $bB;
    }
    .titles-r {
      .titles-r-a {
        border-bottom: $bB;
        width: 829px;
        height: 29px;
        line-height: 29px;
      }
      .titles-r-b {
        display: flex;
        .titles-r-b-a {
          width: 98px;
          border-bottom: $bB;
          height: 29px;
          line-height: 29px;
          border-right:$bB;
        }
        .titles-r-b-b {
          width: 734px;
          border-bottom: $bB;
          height: 29px;
          line-height: 29px;
        }
      }
      .titles-r-c {
        display: flex;
        justify-content: start;
        .titles-r-c-a {
          .titles-r-c-a-a {
            display: flex;
            p {
              width: 32px;
              border-right: $bB;
              border-bottom: $bB;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 68px;
            }
          }
          .titles-r-c-a-b {
            display: flex;
            p {
              width: 32px;
              border-right: $bB;
              border-bottom: $bB;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 38px;
            }
          }
        }
        .titles-r-c-b {
          .titles-r-c-b-a {
            .titles-r-c-b-a-a {
              width: 168px;
              border-right: $bB;
              border-bottom: $bB;
              height: 29px;
              line-height: 29px;
            }
            .titles-r-c-b-a-b {
              display: flex;
              justify-content: start;
              p {
                width: 40px;
                border-right: $bB;
                border-bottom: $bB;
                height: 38px;
              }
              p:nth-child(4) {width: 45px;}
            }
          }
          .titles-r-c-b-b {
            display: flex;
            justify-content: start;
            p {
              width: 40px;
              border-right: $bB;
              border-bottom: $bB;
              height: 38px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            p:nth-child(4) {width: 45px;}
          }
        }
        .titles-r-c-c {
          .titles-r-c-c-a {
            .titles-r-c-c-a-a {
              width: 95px;
              border-right: $bB;
              border-bottom: $bB;
              height: 29px;
              line-height: 29px;
            }
            .titles-r-c-c-a-b {
              display: flex;
              justify-content: start;
              p{
                width: 31px;
                border-right: $bB;
                border-bottom: $bB;
                height: 38px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
          .titles-r-c-c-b {
            display: flex;
            justify-content: start;
            p{
              width: 31px;
              border-right: $bB;
              border-bottom: $bB;
              height: 38px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
        .titles-r-c-d {
          .titles-r-c-d-a {
            .titles-r-c-d-a-a {
              width: 304px;
              border-right: $bB;
              border-bottom: $bB;
              height: 29px;
              line-height: 29px;
            }
            .titles-r-c-d-a-b {
              display: flex;
              justify-content: start;
              p {
                width: 60px;
                border-right: $bB;
                border-bottom: $bB;
                height: 38px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
          .titles-r-c-d-b {
            display: flex;
            justify-content: start;
            p {
              border-right: $bB;
              border-bottom: $bB;
              height: 38px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            p:nth-child(1) {width: 60px}
            p:nth-child(2) {width: 29px}
            p:nth-child(3) {width: 30px}
            p:nth-child(4) {width: 30px}
            p:nth-child(5) {width: 29px}
            p:nth-child(6) {width: 30px}
            p:nth-child(7) {width: 29px}
            p:nth-child(8) {width: 60px}
          }
        }
        .titles-r-c-e {
          .titles-r-c-e-a {
            .titles-r-c-e-a-a {
              width: 161px;
              border-bottom: $bB;
              height: 29px;
              line-height: 29px;
            }
            .titles-r-c-e-a-b {
              display: flex;
              p {
                border-bottom: $bB;
                border-right: $bB;
                height: 38px;
                //line-height: 38px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              p:nth-child(1) {width: 62px;}
              p:nth-child(2) {width: 97px;border-right: none;}
            }
          }
          .titles-r-c-e-b {
            display: flex;
            justify-content: start;
            p {
              width: 31px;
              border-bottom: $bB;
              border-right: $bB;
              height: 38px;
              line-height: 38px;
            }
            p:nth-child(1) {width: 62px}
            p:nth-child(4) {border-right: none;}
          }
        }
      }
    }
  }
  .jt-content {
    .jt-content-nr {
      display: flex;
      justify-content: start;
      .lef-nr {
        width: 46px;
        height: 60px;
        border-right: $bB;
        border-bottom: $bB;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      @media (max-width: 12450px) {

      }
      .content-right {
        display: flex;
        p{
          border-right: $bB;
          border-bottom: $bB;
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            font-weight: bold;
          }
        }
        p:nth-child(1) {width: 32px}
        p:nth-child(2) {width: 32px}
        p:nth-child(3) {width: 32px}

        p:nth-child(4) {width: 40px}
        p:nth-child(5) {width: 40px}
        p:nth-child(6) {width: 40px}
        p:nth-child(7) {width: 45px}

        p:nth-child(8) {width: 31px}
        p:nth-child(9) {width: 31px}
        p:nth-child(10) {width: 31px}

        p:nth-child(11) {width: 60px}
        p:nth-child(12) {width: 29px}
        p:nth-child(13) {width: 30px}
        p:nth-child(14) {width: 30px}
        p:nth-child(15) {width: 29px}
        p:nth-child(16) {width: 30px}
        p:nth-child(17) {width: 29px}
        p:nth-child(18) {width: 60px}

        p:nth-child(19) {width: 62px}
        p:nth-child(20) {width: 31px}
        p:nth-child(21) {width: 31px}
        p:nth-child(22) {width: 31px; border-right: none;}
      }
    }
  }
</style>
